import { TariffBlock } from '@libs/components';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from '@store/store';
import type { RefObjectType } from '@libs/types';
import { useEffect } from 'react';
import { checkSubscriptionThunk } from '@store/store/thunk/investorpro.thunk';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';
import { getProductListThunk } from '@store/store/thunk/product.thunk';
import { bodyReq } from '@libs/components/layout/Layout';

import { Footer } from './components/Footer';
import { InvestorFunctional } from './components/InvestorFunctional';
import { Advantages } from './components/Advantages';
import { DynamicTitle } from './components/DynamicTitle';
import { Header } from './components/Header';

export const LandingPage = () => {
    // const dispatch = useDispatch<AppDispatch>();
    const refs: RefObjectType<HTMLDivElement> = {};

    // const activeTariff = useSelector((state: RootState) => state.investorPro.activeTariff);
    // const productGuids = useSelector((state: RootState) => state.product.productGuids);
    // const isProductLoading = useSelector((state: RootState) => state.product.isLoading);

    // const setChildRefs = (childRefs: RefObjectType<HTMLDivElement>) => {
    //     Object.assign(refs, childRefs);
    // };

    // useEffect(() => {
    //     if (productGuids[ProductMnemoCodes.INVESTOR_PRO]) {
    //         dispatch(getProductListThunk(bodyReq)).then(() => {
    //             dispatch(checkSubscriptionThunk(productGuids[ProductMnemoCodes.INVESTOR_PRO]));
    //         });
    //     }
    // }, [productGuids]);

    return (
        <>
            <Header refs={refs} />
            <DynamicTitle />
            <Advantages />
            <InvestorFunctional />
            <Footer refs={refs} />
            {/*{!isProductLoading && (*/}
            {/*    <TariffBlock*/}
            {/*        setRefs={setChildRefs}*/}
            {/*        productGuid={productGuids[ProductMnemoCodes.INVESTOR_PRO]}*/}
            {/*        activeTariff={activeTariff}*/}
            {/*        isInvestorProPage={!activeTariff}*/}
            {/*    />*/}
            {/*)}*/}
        </>
    );
};
