export type DynamicConfig = {
    [key: string]: string;
    environment: 'DEV' | 'PROD';
};

export const defaultConfig: DynamicConfig = {
    REACT_APP_MONETA_API: 'https://demo.moneta.ru/assistant.htm',
    REACT_APP_DS_REGISTRATION: 'https://passport-test.moex.com/registration',
    REACT_APP_ALGOPACK_API_DESC: 'https://www.moex.com/a2193',
    REACT_APP_MOEXALGO_GITHUB: 'https://github.com/moexalgo/moexalgo',
    REACT_APP_MOEXALGO_EXAMPLES: 'https://github.com/moexalgo/moexalgo/blob/main/samples/quick_start.ipynb',
    REACT_APP_MOEXALGO_DETAILS: 'https://github.com/WISEPLAT/backtrader_moexalgo/',
    REACT_APP_MOEXALGO_TELEGRAM: 'https://t.me/moex_algopack',
    REACT_APP_INVESTORPRO_DYNAMIC_TITLES:
        'опытных инвесторов&управляющих&финансовых консультантов&советников&всех, кто занимается долгосрочными инвестициями',
    REACT_APP_INVESTORPRO_TELEGRAM_LINK: 'https://t.me/MOEX_DATASHOP',
    REACT_APP_MOEXDATA_LINK: 'https://int-data.moex.com/products/moexdata/',
    REACT_APP_TRADE_RADAR: 'https://trade-radar.ru/',
    REACT_APP_FOOTER_VK_LINK: 'https://vk.com/club226048516',
    REACT_APP_FOOTER_TELEGRAM_LINK: 'https://t.me/MOEX_DATASHOP',
    REACT_APP_ALGOPACK_GUID: '867ec3a1-ab69-470b-9f7b-241c915bae9a',
    REACT_APP_MOEXDATA_GUID: 'e58ed763-928c-4155-bee9-fdbaaadc1544',
    REACT_APP_INVESTORPRO_GUID: 'e58ed763-928c-4155-bee9-fdbaaadc1555',
    REACT_APP_MARKETDIVE_GUID: 'e58ed763-928c-4155-bee9-fdbaaadc1566',
    REACT_APP_COMPLIENCETOOL_GUID: 'e58ed763-928c-4155-bee9-fdbaaadc1577',
    REACT_APP_PRICECENTER_GUID: 'e58ed763-928c-4155-bee9-fdbaaadc1588',
    REACT_APP_PROPACK_GUID: 'e58ed763-928c-4155-bee9-fdbaaadc1599',
    REACT_APP_CKI_GUID: 'e58ed763-928c-4155-bee9-fdbaaadc1510',
    REACT_APP_MARKETVISION_GUID: 'e58ed763-928c-4155-bee9-fdbaaadc1511',
    REACT_APP_TRADERADAR_GUID: 'e58ed763-928c-4155-bee9-fdbaaadc1512',
    REACT_APP_INVESTOR_PRO_LINK: 'https://int-data-ipro.moex.com/',
    REACT_APP_DS_URL: 'http://localhost:3000',
    environment: 'DEV',
};

export const dynamicConfigUrl = 'config.json';
