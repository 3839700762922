import { type AxiosResponse } from 'axios';
import { type Cart, type ResponseInfo } from '@libs/types';
import { httpClient } from '@configs/httpclient.config';

export const CHAT_HASH_URL = '/moex-datashop-profileservice/api/userprofile/v1/chat-service/get-hash';
const CHAT_SRC_SUBSTR = 'https://chat-marketplace.beta.moex.com/get-bootstrap/channel_3';

export const getChatHash = async (): Promise<AxiosResponse<ResponseInfo<Cart>>> => await httpClient.get(CHAT_HASH_URL);

export const loadScript = (url?: string, content?: string, defer = true) => {
    const script = document.createElement('script');
    script.innerHTML = content ?? '';
    script.src = url ?? '';
    script.id = 'SOME_CHAT_CUSTOM_ID';
    script.async = false;
    script.defer = defer;
    document.body.appendChild(script);
};

export let chatExternalControl: any;

type AuthLevels = {
    unauthorized: 'unauthorized';
    partially_authorized: 'partially_authorized';
    fully_authorized: 'partial_authorized';
};

export const addChatHandler = (authLevel: keyof AuthLevels) => {
    //removeScriptBySrc(CHAT_SRC_SUBSTR);

    switch (authLevel) {
        case 'fully_authorized': {
            console.log('window._env_.REACT_APP_CHAT_AUTHORIZED_URL', window._env_.REACT_APP_CHAT_AUTHORIZED_URL);
            loadScript(window._env_.REACT_APP_CHAT_AUTHORIZED_URL);
            break;
        }
        case 'partially_authorized': {
            console.log('config.REACT_APP_CHAT_PARTIALLY_AUTHORIZED_URL', window._env_.REACT_APP_CHAT_PARTIALLY_AUTHORIZED_URL);
            loadScript(window._env_.REACT_APP_CHAT_PARTIALLY_AUTHORIZED_URL);
            break;
        }
        default: {
            console.log('window._env_.REACT_APP_CHAT_UNAUTHORIZED_URL', window._env_.REACT_APP_CHAT_UNAUTHORIZED_URL);
            loadScript(window._env_.REACT_APP_CHAT_UNAUTHORIZED_URL);
        }
    }

    window.getWebChatCraftTalkExternalControl = (externalControl: any) => {
        if (externalControl) {
            chatExternalControl = externalControl;
            console.log(externalControl);
        }

        externalControl.on('webchatOpened', () => {
            console.log('webchatOpened event');
        });
        externalControl.closeWidget();
    };
};

export const removeChatHandler = () => {
    const arr = document.querySelectorAll('[class^="webchat"]');

    if (arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
            arr[i].remove();
        }
    }
};
